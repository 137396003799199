@charset "UTF-8";
/*
    Variables File
*/
/* Colors */
/* Date Picker*/
/* Grainbow */
/* Notes */
/* Screen Size - match the bootstrap breakpoints */
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

/*
    Converts pixels to rems. Includes fallback to pixels.
*/
/* Visually hidden allows screen readers to read what is on the screen.
    Use this in place of display: none; unless you do not want screen readers to have access to the content.
*/
/*
    Variables File
*/
/* Colors */
/* Date Picker*/
/* Grainbow */
/* Notes */
/* Screen Size - match the bootstrap breakpoints */
/*
    Converts pixels to rems. Includes fallback to pixels.
*/
html {
  box-sizing: border-box;
  position: relative;
  min-height: 100%;
}

*:before,
*::after {
  box-sizing: inherit;
}

body {
  font-size: 10px;
  font-size: 1rem;
  background-color: #fff;
  text-rendering: optimizeLegibility;
  margin-bottom: 140px;
}

@media (min-width: 575.98px) {
  body {
    margin-bottom: 120px;
  }
}

/*
    purple color:  #262868
    yellow colour: #f9a30c  
    dark text:     #636061 
*/
/*
    purple color:  #262868
    yellow colour: #f9a30c  
    dark text:     #636061 
*/
::selection {
  background: #262868;
  color: #fff;
}

body {
  color: #636061;
}

.hidden {
  visibility: hidden;
  height: 0;
}

.purple {
  color: #262868;
}

.btn-primary {
  color: #fff;
  background-color: #262868;
  border-color: #262868;
}

#logo {
  width: 300px;
}

.main-menu {
  width: 100% !important;
}

.multiselect-native-select .dropdown-menu {
  max-height: 400px;
  overflow-y: scroll;
}

nav {
  border-bottom: 3px solid #262868;
}

#navigation {
  padding-left: 50px;
  width: 100%;
}

nav a {
  font-size: 1.1em;
  color: #262868;
}

nav a:hover {
  color: #f9a30c;
  text-decoration: underline;
}

nav a img {
  margin-left: -30px;
}

.nav-item {
  padding: 0 0.5em;
  font-weight: 500;
}

#user-dropdown {
  width: 300px;
}

.container {
  padding-bottom: 40px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  padding-top: 20px;
  height: 180px;
  line-height: 40px;
  background-color: #262868;
}

@media (min-width: 768px) {
  footer {
    height: 140px;
  }
}

footer ~ .footer-spacer:first-of-type {
  display: none;
}

#footer-links {
  color: #fff;
  margin-bottom: 0;
  font-size: small;
}

#footer-links > .footer-item:first-child .footer-spacer {
  display: none;
}

.footer-item {
  display: inline-block;
}

.footer-item a {
  color: #fff;
}

.footer-item a:hover {
  color: #f9a30c;
  text-decoration: none;
}

.footer-spacer {
  margin: 0 1em;
}

.carousel-item {
  height: 65vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.portfolio-item {
  margin-bottom: 30px;
}

#announcement-container {
  position: relative;
  width: 100%;
  min-height: 150px;
  margin: 0;
  padding-top: 20px;
  text-align: center;
  background-color: #f9a30c;
  color: #262868;
  padding-bottom: 1px;
}

#announcement-text, #annoincement-header {
  height: auto;
  margin: 0;
  padding-bottom: 10px;
}

#annonymous, #authenticated {
  display: none;
}

.dropdown-menu.closed {
  display: none;
}

/* cssload */
.cssload-fond {
  position: fixed;
  margin: auto;
  bottom: 25px;
  left: 25px;
}

.renderInBody .cssload-fond {
  z-index: 9999;
}

.cssload-container-general {
  -moz-animation: cssload-animball_two 1.15s infinite;
  -o-animation: cssload-animball_two 1.15s infinite;
  -webkit-animation: cssload-animball_two 1.15s infinite;
  animation: cssload-animball_two 1.15s infinite;
  width: 38px;
  height: 38px;
}

.cssload-internal {
  width: 38px;
  height: 38px;
  position: absolute;
}

.cssload-ballcolor {
  width: 17px;
  height: 17px;
  border-radius: 50%;
}

.cssload-ball_1, .cssload-ball_2, .cssload-ball_3, .cssload-ball_4 {
  position: absolute;
  -moz-animation: cssload-animball_one 1.15s infinite ease;
  -o-animation: cssload-animball_one 1.15s infinite ease;
  -webkit-animation: cssload-animball_one 1.15s infinite ease;
  animation: cssload-animball_one 1.15s infinite ease;
}

.cssload-ball_1 {
  background-color: #5cb85c;
  top: 0;
  left: 0;
}

.cssload-ball_2 {
  background-color: #5cb85c;
  top: 0;
  left: 21px;
}

.cssload-ball_3 {
  background-color: #5cb85c;
  top: 21px;
  left: 0;
}

.cssload-ball_4 {
  background-color: #5cb85c;
  top: 21px;
  left: 21px;
}

@keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 10px;
    left: 10px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-o-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 10px;
    left: 10px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-ms-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 10px;
    left: 10px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-webkit-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 10px;
    left: 10px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-moz-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 10px;
    left: 10px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@keyframes cssload-animball_two {
  0% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(1.3);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}

@-o-keyframes cssload-animball_two {
  0% {
    -o-transform: rotate(0) scale(1);
  }
  50% {
    -o-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -o-transform: rotate(720deg) scale(1);
  }
}

@-ms-keyframes cssload-animball_two {
  0% {
    -ms-transform: rotate(0) scale(1);
  }
  50% {
    -ms-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -ms-transform: rotate(720deg) scale(1);
  }
}

@-webkit-keyframes cssload-animball_two {
  0% {
    -webkit-transform: rotate(0) scale(1);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(1);
  }
}

@-moz-keyframes cssload-animball_two {
  0% {
    -moz-transform: rotate(0) scale(1);
  }
  50% {
    -moz-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -moz-transform: rotate(720deg) scale(1);
  }
}

.form-login {
  width: 100%;
  max-width: 750px;
}

.login-button {
  width: 100%;
}

#registerForm {
  margin-bottom: 40px;
}

#registerForm .login-details {
  margin-top: 40px;
}

#registerForm .terms-label {
  padding-left: 5px;
}

.terms-text {
  font-size: 14px;
}

.listing-list-item {
  font-size: 13px;
}

.img-thumbnail {
  background-color: transparent;
  border: 0px;
  padding: 0px;
}

.listing-details-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

@media (min-width: 575.98px) {
  #footer {
    width: 140px;
  }
  #footer-links {
    font-size: medium;
  }
}

.listing-details .listing-details-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: #DDDDDD;
  border-bottom: 1px solid #AAAAAA;
}

.listing-details .listing-details-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.listing-details .listing-details-divider {
  border-color: #AAAAAA;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.listing-details .listing-details-extra {
  padding: 1.25rem;
  padding-top: 0;
}

.listing-details .listing-bid-summary .card-header {
  padding: .25rem 0.5rem;
}

.listing-details .listing-bid-summary .card-body {
  padding: .25rem 0.5rem;
}

.listing-details .listing-bid-summary .card-body dd {
  margin-bottom: 0;
}

.listing-details .listing-bid-summary .card-body .final-price-row {
  color: #85144b;
}

.border-gray {
  border-color: #AAAAAA !important;
}

.grid-body {
  padding-bottom: 0;
}

.grid-check-all-filter {
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 5px 3px 3px 6px;
}

.grid-check-all-filter label {
  padding: 0 3px;
  white-space: nowrap;
}

.grid-column-label a, thead > tr.grid-label-row .grid-simple-column-label a, thead > tr.grid-label-row .grid-sortable-column-label a, thead > tr.grid-label-row .actions-column-label a {
  border-color: #efefef;
  border-style: solid;
  border-width: 0 0 1px 0;
  display: block;
  margin-bottom: 1px;
  padding: 3px;
}

.grid-column-label a:hover, thead > tr.grid-label-row .grid-simple-column-label a:hover, thead > tr.grid-label-row .grid-sortable-column-label a:hover, thead > tr.grid-label-row .actions-column-label a:hover {
  border-color: #89b6ea;
  border-width: 0 0 2px 0;
  cursor: pointer;
  margin-bottom: 0;
  text-decoration: none;
}

.grid-column-label a:hover .sort-icon, thead > tr.grid-label-row .grid-simple-column-label a:hover .sort-icon, thead > tr.grid-label-row .grid-sortable-column-label a:hover .sort-icon, thead > tr.grid-label-row .actions-column-label a:hover .sort-icon {
  display: inline-block;
}

.grid-column-label a .sort-icon-wrapper, thead > tr.grid-label-row .grid-simple-column-label a .sort-icon-wrapper, thead > tr.grid-label-row .grid-sortable-column-label a .sort-icon-wrapper, thead > tr.grid-label-row .actions-column-label a .sort-icon-wrapper {
  display: inline-block;
  width: 15px;
  margin: 0 4px;
}

.grid-column-label a .sort-icon, thead > tr.grid-label-row .grid-simple-column-label a .sort-icon, thead > tr.grid-label-row .grid-sortable-column-label a .sort-icon, thead > tr.grid-label-row .actions-column-label a .sort-icon, .grid-column-label a .alternative-sort-icon, thead > tr.grid-label-row .grid-simple-column-label a .alternative-sort-icon, thead > tr.grid-label-row .grid-sortable-column-label a .alternative-sort-icon, thead > tr.grid-label-row .actions-column-label a .alternative-sort-icon {
  display: none;
  min-width: 15px;
}

.grid-column-label a.selected-sort, thead > tr.grid-label-row .grid-simple-column-label a.selected-sort, thead > tr.grid-label-row .grid-sortable-column-label a.selected-sort, thead > tr.grid-label-row .actions-column-label a.selected-sort {
  border-color: #004990;
  border-style: solid;
  border-width: 0 0 1px 0;
}

.grid-column-label a.selected-sort .sort-icon, thead > tr.grid-label-row .grid-simple-column-label a.selected-sort .sort-icon, thead > tr.grid-label-row .grid-sortable-column-label a.selected-sort .sort-icon, thead > tr.grid-label-row .actions-column-label a.selected-sort .sort-icon {
  display: inline-block;
}

.grid-column-label a.selected-sort:hover, thead > tr.grid-label-row .grid-simple-column-label a.selected-sort:hover, thead > tr.grid-label-row .grid-sortable-column-label a.selected-sort:hover, thead > tr.grid-label-row .actions-column-label a.selected-sort:hover {
  border-color: #89b6ea;
  border-width: 0 0 2px 0;
}

.grid-data-row .actions-column-data {
  text-align: center;
  white-space: nowrap;
}

.grid-data-row.no-content td {
  background-color: #bbb;
  color: #666;
}

.grid-date-filter {
  border-color: #ccc;
  border-radius: 4px 0 0 4px !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.grid-filter, .grid-date-filter {
  background-color: rgba(137, 182, 234, 0.2);
  padding: 5px 2px 5px 5px;
}

.grid-filter.active, .active.grid-date-filter {
  background-color: #dff0d8;
  color: #333;
}

.grid-filter.disabled, .disabled.grid-date-filter {
  background-color: #eee;
  color: #eee;
}

.grid-filter:focus, .grid-date-filter:focus {
  background-color: white;
  color: #333;
}

.grid-filter .option-empty-value, .grid-date-filter .option-empty-value {
  color: #999;
}

input[type="text"].grid-filter.active, input.grid-date-filter[type="text"].active,
input[type="number"].grid-filter.active,
input.grid-date-filter[type="number"].active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

select.grid-filter, select.grid-date-filter {
  color: #999;
}

.grid-filter-panel.collapsed-panel {
  display: none;
}

thead > tr.grid-filter-row {
  border-width: 0 !important;
}

thead > tr.grid-filter-row > th {
  border-width: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
  vertical-align: top;
}

thead > tr.grid-filter-row > th input[type=number]::-webkit-inner-spin-button,
thead > tr.grid-filter-row > th input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.grid-stub-filter {
  background-color: white;
  border: 1px solid #efefef;
  border-radius: 4px !important;
  display: flex;
  height: 34px;
  width: 100%;
}

thead > tr.grid-label-row {
  border-top-width: 0 !important;
}

thead > tr.grid-label-row .grid-column-label, thead > tr.grid-label-row .grid-simple-column-label, thead > tr.grid-label-row .grid-sortable-column-label, thead > tr.grid-label-row .actions-column-label {
  border: 1px solid #ddd !important;
  padding: 0 !important;
  vertical-align: top;
}

thead > tr.grid-label-row .grid-simple-column-label div {
  background-color: #fdfdfe;
  color: #444;
  margin-bottom: 0;
  min-height: 42px;
  padding: 0 6px;
}

thead > tr.grid-label-row .grid-simple-column-label div:hover {
  cursor: default;
}

thead > tr.grid-label-row .grid-simple-column-label.currency, thead > tr.grid-label-row .grid-simple-column-label.numeric {
  text-align: right;
}

thead > tr.grid-label-row .grid-simple-column-label.action {
  border-right-width: 0 !important;
}

thead > tr.grid-label-row .grid-sortable-column-label a {
  background: #89b6ea;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(rgba(255, 0, 0, 0), rgba(137, 182, 234, 0.2));
  /*Safari 5.1-6*/
  background: -o-linear-gradient(rgba(255, 0, 0, 0), rgba(137, 182, 234, 0.2));
  /*Opera 11.1-12*/
  background: -moz-linear-gradient(rgba(255, 0, 0, 0), rgba(137, 182, 234, 0.2));
  /*Fx 3.6-15*/
  background: linear-gradient(rgba(255, 0, 0, 0), rgba(137, 182, 234, 0.2));
  /*Standard*/
  margin-bottom: 0;
  min-height: 42px;
  padding: 0 6px;
}

thead > tr.grid-label-row .grid-sortable-column-label.currency, thead > tr.grid-label-row .grid-sortable-column-label.numeric {
  text-align: right;
}

thead > tr.grid-label-row .grid-sortable-column-label.action {
  border-right-width: 0 !important;
}

thead > tr.grid-label-row .actions-column-label {
  background-color: #fafafa;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.site-compression thead > tr.grid-label-row .grid-sortable-column-label a {
  min-height: 28px;
}

.site-compression thead > tr.grid-label-row .grid-simple-column-label div {
  min-height: 28px;
}

.grid-pagination {
  color: #262868;
  text-align: center;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 8px;
}

.grid-pagination ul {
  display: flex;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: center;
  list-style-type: none;
  min-height: 33px;
}

.grid-pagination ul li {
  border: 0 solid #262868;
  border-width: 1px 0 1px 1px;
  display: inline-block;
  padding: 5px 0;
  min-width: 40px;
}

.grid-pagination ul li:first-child {
  border-radius: 4px 0 0 4px;
  border-width: 1px;
  margin-right: 7px;
}

.grid-pagination ul li:last-child {
  border-radius: 0 4px 4px 0;
  border-width: 1px;
  margin-left: 7px;
}

.grid-pagination ul li:nth-last-child(2) {
  border-right-width: 1px;
}

.grid-pagination ul li.selected {
  background-color: #262868;
  color: white;
}

.grid-pagination ul li.selected:hover {
  cursor: not-allowed;
}

.grid-pagination ul li.disabled {
  background-color: white;
  border-color: #6397d3;
  color: #89b6ea;
}

.grid-pagination ul li.disabled:hover {
  cursor: not-allowed;
}

.grid-pagination ul li:hover:not(.disabled):not(.selected) {
  background-color: #262868;
  color: white;
  cursor: pointer;
}

.grid-pagination .go-to-page, .grid-pagination .pagesize-control {
  border: solid 1px #262868;
  border-radius: 3px;
  display: flex;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0 0 10px 12px;
  padding: 5px 8px;
  text-align: left;
}

.grid-pagination .go-to-page .goto,
.grid-pagination .go-to-page .of, .grid-pagination .pagesize-control .goto,
.grid-pagination .pagesize-control .of {
  display: inline-block;
  font-size: 0.95em;
  line-height: 1.2em;
  margin-top: 3px;
}

.grid-pagination .go-to-page .of, .grid-pagination .pagesize-control .of {
  margin: 3px 3px 0 0;
}

.grid-pagination .go-to-page input, .grid-pagination .pagesize-control input {
  border: 1px solid #262868;
  height: 20px;
  line-height: normal;
  margin: 1px 5px 0 5px;
  padding-bottom: 1px;
  text-align: center;
  width: 27px;
}

.grid-pagination .go-to-page input[type=number]::-webkit-inner-spin-button,
.grid-pagination .go-to-page input[type=number]::-webkit-outer-spin-button, .grid-pagination .pagesize-control input[type=number]::-webkit-inner-spin-button,
.grid-pagination .pagesize-control input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.grid-pagination .go-to-page input[type=number], .grid-pagination .pagesize-control input[type=number] {
  -moz-appearance: textfield;
}

.grid-tab-buttons {
  margin-bottom: 10px;
}

.site-compression .grid-tab-buttons {
  margin-bottom: 0;
}

table.grid-table {
  margin-bottom: 0;
}

div.table.table-responsive {
  margin-bottom: 20px;
}

#home-bottom-section {
  margin-bottom: 40px;
}

#home-bottom-section h2 {
  color: #262868;
}

#home-bottom-section a.button-link {
  padding: 12px 100px;
}

#home-bottom-section a.button-link span {
  color: #fff;
  width: 400px;
  max-width: 100%;
  font-size: 1.3em;
  border: none;
  border-radius: 15px;
}

#home-bottom-section a.button-link :hover {
  background-color: #c1bdce;
  color: #262868;
  cursor: pointer;
}

#carousel .carousel-inner .carousel-caption {
  top: 45%;
  right: 0;
  left: 0;
}

#carousel .carousel-inner .carousel-caption h1 {
  color: #262868;
  font-size: 3.5em;
  font-weight: 500;
  text-align: center;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  #carousel .carousel-inner .carousel-caption h1 {
    font-size: 5em;
    top: 30%;
    text-align: left;
    float: right;
    width: 600px;
  }
}

@media (min-width: 768px) {
  #carousel .carousel-inner .carousel-caption {
    text-align: left;
    top: 30%;
    right: 5%;
  }
}

#carousel .carousel-indicators li {
  background-color: #262868;
}

#carousel .carousel-control-next, #carousel .carousel-control-prev {
  width: 10%;
}

#carousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23262868' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

#carousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23262868' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

#how-it-works {
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

@media (min-width: 1200px) {
  #how-it-works {
    justify-content: flex-start;
  }
}

#how-it-works .step {
  position: relative;
  margin-right: 10px;
}

#how-it-works .step .next-arrow {
  position: absolute;
  z-index: 1;
  top: 42%;
  right: -20px;
  width: 35px;
}

#how-it-works .step .step-image {
  width: 170px;
}

@media (min-width: 768px) {
  #how-it-works .step .step-image {
    width: 180px;
  }
}

@media (min-width: 1200px) {
  #how-it-works .step .step-image {
    width: 200px;
  }
}

#right-side img {
  max-width: 300px;
  margin: 2% 5%;
}

@media (min-width: 768px) {
  #right-side img {
    margin: 20% 0 0 15%;
    max-width: 500px;
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px 10px 40px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 3;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
  min-width: 350px;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px;
}

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

.bd-callout > i {
  font-size: 40px;
  margin-right: .3em;
  min-width: 50px;
  width: 50px;
  float: left;
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout code {
  border-radius: .25rem;
}

.bd-callout + .bd-callout {
  margin-top: -.25rem;
}

.bd-callout-body > p {
  overflow: hidden;
}

.bd-callout-danger {
  border-left-color: #dc3545 !important;
}

.bd-callout-danger h4, .bd-callout-danger i {
  color: #dc3545 !important;
}

.bd-callout-dark {
  border-left-color: #343a40 !important;
}

.bd-callout-dark h4, .bd-callout-dark i {
  color: #343a40 !important;
}

.bd-callout-info {
  border-left-color: #17a2b8 !important;
}

.bd-callout-info h4, .bd-callout-info i {
  color: #17a2b8 !important;
}

.bd-callout-light {
  border-left-color: #f8f9fa !important;
}

.bd-callout-light h4, .bd-callout-light i {
  color: #f8f9fa !important;
}

.bd-callout-muted {
  border-left-color: #6c757d !important;
}

.bd-callout-muted h4, .bd-callout-muted i {
  color: #6c757d !important;
}

.bd-callout-primary {
  border-left-color: #007bff !important;
}

.bd-callout-primary h4, .bd-callout-primary i {
  color: #007bff !important;
}

.bd-callout-secondary {
  border-left-color: #6c757d !important;
}

.bd-callout-secondary h4, .bd-callout-secondary i {
  color: #6c757d !important;
}

.bd-callout-success {
  border-left-color: #28a745 !important;
}

.bd-callout-success h4, .bd-callout-success i {
  color: #28a745 !important;
}

.bd-callout-warning {
  border-left-color: #ffc107 !important;
}

.bd-callout-warning h4, .bd-callout-warning i {
  color: #ffc107 !important;
}

.bd-callout-white {
  border-left-color: #fff !important;
}

.bd-callout-white h4, .bd-callout-white i {
  color: #fff !important;
}
